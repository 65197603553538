<template>
  <ValidationObserver v-slot="{ invalid, valid }">
  <form @submit.prevent="valid && !errors && next()" class="flex-row wrap">
    <h2>Datos generales del centro de trabajo {{ append }}</h2>
    <hr class="gob"/>

    <ValidationProvider class="field nombre" :rules="rulesNombre" v-slot="v">
      <b-form-group label="Nombre del centro de trabajo*">
        <b-input v-model="value.nombre" maxLength=100
          v-uppercase :state="$state(v)" ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field clave" :rules="rulesClave" v-slot="v">
      <b-form-group label="Clave*">
        <b-input v-model="value.clave" maxLength=10
          v-uppercase :state="$state(v) && !errors" :disabled="update"
          @input="updatedClave()"></b-input>
        <b-error :state="$state(v) && !errors">
          <template v-if="errorUnique">{{ errorUnique }}</template>
          <template v-else-if="errorTipo">{{ errorTipo }}</template>
          <template v-else>{{ v.errors[0] }}</template>
        </b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field tipo" rules="required" v-slot="v">
      <b-form-group label="Sistema educativo u organismo*">
        <b-select v-model="value.tipo" :state="$state(v)" plain disabled
          :options="tiposEscuela" text-field="nombre" value-field="id">
        </b-select>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field aulas" rules="required" v-slot="v">
      <b-form-group label="Aulas*">
        <b-input type=number min=0 :disabled="unitaria"
          v-model="value.aulas" :state="$state(v)" ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field grupos" rules="required" v-slot="v">
      <b-form-group label="Grupos*">
        <b-input type=number min=0
          v-model="value.grupos" :state="$state(v)" ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <div class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared :disabled="invalid || errors"
        @click.prevent="next()">{{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    value: Object,
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
  },
  data: () => ({
    unitaria: false,
    update: false,
    errorTipo: undefined,
    errorUnique: undefined,
  }),
  computed: {
    ...mapState('encuesta', ['tiposEscuela']),
    ...mapState('escuela', ['escuela']),
    rulesNombre: () => ({
      required: true,
      regex: /^[0-9A-ZÁÉÍÓÚÜÑ&"' .,()-/]+$/,
    }),
    rulesClave: () => ({
      required: true,
      regex: /^(0[1-9]|[1-2]\d|3[0-2])[A-Z]{3}\d{4}[A-Z]$/,
      verificador_clave: true,
    }),
    errors() {
        return this.errorTipo || this.errorUnique
    },
  },
  created() {
    this.getTiposEscuela().then( () => {
      if (!this.escuela) return

      this.update = true
      // Avoid mutating a property directly because when
      // parent re-renders it will overwritte the value
      this.$emit('input', {
        tipo:   this.escuela.tipo.id,
        clave:  this.escuela.clave,
        nombre: this.escuela.nombre,
        grupos: this.escuela.grupos,
        aulas:  this.escuela.aulas,
      })

    })
  },
  methods: {
    ...mapActions('encuesta', ['getTiposEscuela']),
    ...mapActions('escuela', ['exists']),
    async next() {
      this.value.aulas = Number(this.value.aulas)

      if (!this.escuela) {
        const exists = await this.exists(this.value.clave);
        if (exists) {
            this.errorUnique = 'Ya existe la clave'
            return
        }
      }
      this.$emit('next')
      this.$emit('submit', { ...this.value })
    },
    updatedClave() {
      if (this.value.clave.length < 10) {
          this.errorUnique = null
      }
      if (!this.value.clave || this.value.clave.length < 5) {
        return;
      }
      const keyType = this.value.clave.substring(2, 5);
      const tipo = this.tiposEscuela.find(t => t.siglas === keyType)

      if (!tipo) {
        this.errorTipo = 'No existe un sistema educativo asociado'
        this.value.tipo = undefined

      } else {
        this.errorTipo = undefined
        this.value.tipo = tipo.id
      }
    },
  }
}
</script>

<style scoped>
  .tipo, .nombre, .clave, .aulas, .grupos { width: 100%; }
  .checkbox { margin: 0 0 10px 0; }
  @media screen and (min-width: 760px) {
    .grupos { width: 50%; }
    .nombre, .aulas { width: auto; flex-grow: 1; margin-right: 20px; }
    .clave  { width: 30%;  min-width: 200px; }
  }
</style>
