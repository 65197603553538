<template>
  <ValidationObserver v-slot="{ valid, invalid }">
  <form @submit.prevent="valid && $emit('next')">
    <h2>Datos de responsable {{ append }}</h2>
    <hr class=gob />

    <ValidationProvider class="field full-width nombre" rules="required" v-slot="v">
      <b-form-group label="Nombre completo*">
        <b-input v-model="value.responsable" :state="$state(v)"
          v-uppercase maxLength="80"></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>


    <div class="grid">
    <ValidationProvider class="field" rules="required" v-slot="v">
      <b-form-group label="Cargo*">
        <b-input v-model="value.cargo" :state="$state(v)" v-uppercase
          list="cargos-list" @input="loadCargos()"></b-input>
        <datalist id="cargos-list">
          <option v-for="c in cargos" :key="c">{{ c }}</option>
        </datalist>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
      <!--label>Cargo*</label>
      <autocomplete :classes="v.classes"
        v-model="value.cargo" :items="cargos" @input="loadCargos()">
      </autocomplete>
      <div class="error">{{ v.errors[0] }}</div-->
    </ValidationProvider>

    <ValidationProvider class="field" rules="required|min:10" v-slot="v">
      <b-form-group label="Teléfono Movil*">
        <b-input v-model="value.celular" :state="$state(v)"
          v-digits maxLength="10"></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field" rules="required|min:10" v-slot="v">
      <b-form-group label="Teléfono Fijo*">
        <b-input v-model="value.telefono" :state="$state(v)"
          v-digits maxLength="10"></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field" v-slot="v">
      <b-form-group label="Extension">
        <b-input v-model="value.ext" :state="$state(v)"
          v-digits maxLength="10"></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field" rules="required|email" v-slot="v">
      <b-form-group label="Correo electrónico*">
        <b-input v-model="value.correo" :state="$state(v)"
          maxLength="40" v-lowercase></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider class="field" :rules="rulesConfirm" v-slot="v">
      <b-form-group label="Confirmacion de correo electrónico*">
        <b-input v-model="emailConfirm" :state="$state(v)"
          maxLength="40" v-lowercase></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </b-form-group>
    </ValidationProvider>
    </div>

    <div class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared :disabled="invalid"
        @click.prevent="$emit('next')">{{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </form>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    value: Object,
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
  },
  data: () => ({ emailConfirm: '', cargos: null, prevSearch: null }),
  created() {
    if (!this.escuela) return

    this.fetchLastEncuesta().then( encuesta => {
      this.$emit('input', {
        responsable: encuesta.responsable,
        cargo: encuesta.cargo,
        celular: encuesta.celular,
        telefono: encuesta.telefono,
        ext: encuesta.ext,
        correo: encuesta.correo,
      })

      this.emailConfirm = encuesta.correo
    })
  },
  computed: {
    ...mapState('escuela', ['escuela', 'encuesta']),
    rulesConfirm() {
      return {
        required: true,
        is: this.value.correo,
      }
    },
  },
  methods: {
    ...mapActions('escuela', ['fetchLastEncuesta']),
    ...mapActions('encuesta', ['searchCargos']),
    loadCargos() {
      if (!this.value.cargo || this.value.cargo.length < 3) { return }
      const cargo = this.value.cargo.toLowerCase()
      if (!this.prevSearch || !cargo.startsWith(this.prevSearch)) {
        this.prevSearch = cargo
        this.cargos = this.searchCargos(cargo)
      }
    }
  },
}
</script>

<style scoped>
.nombre { display: block; }
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 20px;
}

@media screen and (min-width: 760px) {
  .grid { grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); }
}
</style>
