<template>
  <div class="encuesta">
    <b-alert show class="text-center">
      Dudas y comentarios comuníquese al correo
      <a href="mailto:rededusat.acuse@tveducativa.gob.mx">
        rededusat.acuse@tveducativa.gob.mx</a>
    </b-alert>

    <div class="container">
      <div class="flex-row center-center gap-20">
        <img src="@/assets/educacion_h.png" width="33%" height="100%">
        <h6 class="text-center">
          DIRECCIÓN GENERAL @PRENDE.MX <br>
          DIRECCIÓN DE INGENIERÍA, OPERACIONES E INFORMÁTICA <br>
          DEPARTAMENTO DE ASISTENCIA TÉCNICA A SITIOS DE LA RED EDUSAT <br>
          FORMULARIO PARA SITIOS CON RECEPCIÓN DE RED EDUSAT
        </h6>
      </div>
    </div>

    <div class="container" ref="form">
      <DatosGenerales v-show="step === 1" :append="append" v-model="generales"
        :backLegend="null" @submit="forceEstado($event)"/>
      <Domicilio      v-show="step === 2" :append="append" v-model="domicilio"/>
      <Responsable    v-show="step === 3" :append="append" v-model="responsable"/>
      <Plantilla      v-show="step === 4" :append="append" v-model="plantilla"/>
      <Servicios      v-show="step === 5" :append="append" v-model="servicios"/>
      <Equipamiento   v-show="step === 6" :append="append" v-model="equipamiento"/>
      <Contenidos     v-show="step === 7" :append="append" v-model="contenidos"
        nextLegend="Enviar"/>
    </div>

    <b-modal v-model="modal.show" :title="modal.title" ok-only>
      {{ modal.msg }}
      <template #modal-ok>Aceptar</template>
    </b-modal>

    <!--pre>{{ datos }}</pre-->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DatosGenerales from './DatosGenerales'
import Domicilio from './Domicilio'
import Responsable from './Responsable'
import Plantilla from './Plantilla'
import Servicios from './Servicios'
import Equipamiento from './Equipamiento'
import Contenidos from './Contenidos'

export default {
  components: {
    DatosGenerales, Domicilio, Responsable, Plantilla,
    Servicios, Equipamiento, Contenidos,
  },
  data: () => ({
    step: 1,
    steps: 7,
    generales: {},
    domicilio: {},
    responsable: {},
    plantilla: {},
    servicios: [],
    equipamiento: [],
    contenidos: [],
    modal: {},
  }),
  computed: {
    ...mapState(['info']),
    ...mapState('escuela', ['escuela']),
    ...mapState('geo', ['estados']),
    append() { return `(${this.step}/${this.steps})` },
    datos() {
      return {
        escuela: this.generales,
        domicilio: this.domicilio,
        plantilla: this.plantilla,
        servicios: this.servicios,
        equipamiento: this.equipamiento,
        contenidos: this.contenidos,
        ...this.responsable
      };
    }
  },
  /**
   * Al crearse el componente nos suscribimos automaticamente
   * a los eventos de navegacion hacia atras y adelante
   */
  mounted() {
    if (!this.escuela) {
      const { clave } = this.$route.params
      this.$router.push({ path: `/escuelas/${clave}` })
    }
    this.createListeners();
    this.fetchEstados()
  },
  /**
   * Metodos de navegacion y de envio de datos
   */
  methods: {
    ...mapActions(['fetchUserInfo']),
    ...mapActions('encuesta', ['send', 'update']),
    ...mapActions('geo', ['setEstado', 'fetchEstados', 'fetchMunicipios']),
    createListeners() {
      const children = [...this.$refs.form.children];
      children.forEach( child => {
        child.__vue__.$on('next',
          () => this.step === this.steps ?
            this.submit() :
            this.navigate(1)
        )
        child.__vue__.$on('back', () => this.navigate(-1) )
      });
    },
    forceEstado() {
      if (!this.estado) {
        const id = this.generales.clave.substring(0,2)
        this.setEstado( this.estados.find( e => e.id == id ) ).then( () => {
          this.fetchMunicipios()
        })
      }
    },
    // Si es el ultimo paso entonces envia los datos,
    // en caso contrario navega al siguiente formulario
    navigate(step) {
      this.step += step
      // Obtenemos el primer h2 e input del siguiente form y
      // esperamos al siguiente renderizado para movernos al
      // h2 y despues requerir focus en el input
      const child = this.$refs.form.children[this.step - 1]
      const title = child.querySelector('h2');
      const field = child.querySelector('input, select');
      this.$nextTick( () => {
        title.scrollIntoView()
        field.focus()
      })
    },
    submit() {
      this.send({
        request: this.datos,
        clave: this.generales.clave,
      })
      .then( () => {
        this.modal = {
          show: true,
          title: 'Datos enviados',
          msg: 'Gracias por contestar esta encuesta',
        }
        this.redirect()
      })
      .catch( e => {
        this.modal = {
          show: true,
          title: 'Datos incorrectos',
          msg: 'El formulario contiene los siguientes errores',
          detail: e,
        }
      })
    },
    redirect() {
      this.fetchUserInfo().then(
        () => this.$router.push({ path: '/escuelas/' + this.datos.escuela.clave })
      )
    },
  },
}
</script>

<style>
.encuesta hr { width: 100%; margin: 0 0 40px 0; }
.encuesta h2 { scroll-margin: 90px; margin: 20px 0 20px 0; }
</style>
