<template>
  <ValidationObserver v-slot="{ valid, invalid }">
  <form @submit.prevent="valid && $emit('next')">
    <h2>Servicios {{ append }}</h2>
    <hr class=gob />

    <div v-for="s of value" :key="s.servicio" class="servicios-container">
      <h4>{{ nombreServicio(s.servicio) }}</h4>

      <ValidationProvider class="field estado" rules="required" v-slot="v">
        <b-form-group label="Estado*">
          <b-select v-model="s.estado" :state="$state(v)" plain
            :options="estadosServicio" text-field="nombre" value-field="id"
            @change="changeEstado(s, $event)">
          </b-select>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field" rules="required" v-slot="v">
        <b-form-group label="Suministro*">
          <b-select v-model="s.suministro" :state="$state(v)" :disabled="disable(s)"
            :options="suministrosDisp(s)" text-field="nombre" value-field="id" plain>
          </b-select>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider class="field" :rules="companiaRules(s)" v-slot="v">
        <b-form-group>
          <template #label>Compañia<span v-if="!disable(s)">*</span></template>
          <b-input v-model="s.compania" :state="$state(v)"
            :disabled="disable(s)" maxLength="100"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </b-form-group>
      </ValidationProvider>
    </div>

    <div class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared :disabled="invalid"
        @click.prevent="$emit('next')">{{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </form>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
const SUMINISTRO_NINGUNO = 1

export default {
  props: {
    value: Array,
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
  },
  created() {
    this.getEstadosServicio()
    this.getSuministros()
    this.getServicios().then( allServicios => {
      const serviciosE = this.servicios
      this.$emit('input', allServicios.map( s => {
        const sE = serviciosE.find( i => i.servicio === s.id ) || {};
        const estado     = sE.estado || { id: this.SERVICIO_INEXISTENTE }
        const suministro = sE.suministro || { id: SUMINISTRO_NINGUNO }
        return {
          servicio: s.id,
          estado: estado.id,
          suministro: suministro.id,
          compania: sE.compania || null,
        }
      }))
    })
  },
  computed: {
    ...mapState('escuela', ['escuela', 'servicios']),
    ...mapState('encuesta', ['estadosServicio']),
    ...mapGetters('encuesta', [
        'suministrosServicio', 'nombreServicio', 'SERVICIO_INEXISTENTE'
    ]),
  },
  methods: {
    ...mapActions('encuesta', [
      'getServicios', 'getSuministros', 'getEstadosServicio'
    ]),
    disable(servicio) {
      return !servicio.estado || servicio.estado === this.SERVICIO_INEXISTENTE
    },
    changeEstado(servicio) {
      if (this.disable(servicio)) {
        servicio.suministro = SUMINISTRO_NINGUNO
        servicio.compania = null
      } else {
        servicio.suministro = null
      }
    },
    companiaRules(servicio) {
      return this.disable(servicio) ? '' : 'required'
    },
    suministrosDisp(servicioEscuela) {
      const suministros = this.suministrosServicio(servicioEscuela.servicio)
      return servicioEscuela.estado === this.SERVICIO_INEXISTENTE ?
        suministros :
        suministros.filter( s => s.id !== SUMINISTRO_NINGUNO )
    }
  },
}
</script>

<style scoped>
.servicios-container {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 760px) {
  .servicios-container {
    display: grid;
    grid-template-columns: 165px 220px auto;
    gap: 0 20px;
  }
  .estado { grid-column: 1; }
}
</style>
