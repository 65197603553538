<template>
  <ValidationObserver v-slot="{ valid, invalid }">
  <form @submit.prevent="valid && $emit('next')">
    <h2>Plantilla Escolar y Docente {{ append }}</h2>
    <hr class=gob />

    <div class="grid">
      <b style="grid-area: empty"></b>
      <b style="grid-area: th">Hombres</b>
      <b style="grid-area: tm">Mujeres</b>
      <b style="grid-area: tt">Total</b>

      <b style="grid-area: ta">Alumnos</b>
      <ValidationProvider style="grid-area: ah" rules="required" v-slot="v">
        <b-input v-model="value.alumnosHombres" :state="$state(v)"
          type=number min=0 ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </ValidationProvider>

      <ValidationProvider style="grid-area: am" rules="required" v-slot="v">
        <b-input v-model="value.alumnosMujeres" :state="$state(v)"
          type=number min=0 ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </ValidationProvider>
      <b style="grid-area: at">{{ alumnos }}</b>

      <b style="grid-area: td">Docentes</b>
      <ValidationProvider style="grid-area: dh" rules="required" v-slot="v">
        <b-input v-model="value.docentesHombres" :state="$state(v)"
          type=number min=0 ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </ValidationProvider>

      <ValidationProvider style="grid-area: dm" rules="required" v-slot="v">
        <b-input v-model="value.docentesMujeres" :state="$state(v)"
          type=number min=0 ></b-input>
        <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
      </ValidationProvider>
      <b style="grid-area: dt">{{ docentes }}</b>
    </div>

    <div class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared :disabled="invalid"
        @click.prevent="$emit('next')">{{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </form>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    value: Object,
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
  },
  created() {
    if (!this.escuela) return
    this.$emit('input', { ...this.plantilla })
  },
  computed: {
    ...mapState('escuela', ['escuela', 'plantilla']),
    alumnos() {
      const { alumnosHombres, alumnosMujeres } = this.value
      const sum = Number(alumnosHombres) + Number(alumnosMujeres)
      return sum && sum > 0 ? sum : '---'
    },
    docentes() {
      const { docentesHombres, docentesMujeres } = this.value
      const sum = Number(docentesHombres) + Number(docentesMujeres)
      return sum && sum > 0 ? sum : '---'
    }
  },
  methods: mapActions('escuela', ['fetchPlantilla']),
}
</script>

<style scoped>
.grid {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px;
  display: grid;
  grid-template-areas: "empty th   tm   tt"
                       "ta    ah   am   at"
                       "td    dh   dm   dt";
  gap: 20px;
}

.grid span { height: 40px; }
</style>
