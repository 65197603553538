<template>
  <ValidationObserver v-slot="{ invalid }">
  <form @submit.prevent="valid && $emit('next')">
    <h2>Equipamiento {{ append }}</h2>
    <hr class=gob />

    Indica la cantidad de equipos que tienes funcionales y descompuestos,
    asi como la cantidad de equipos funcionales con internet.

    <div v-for="t of tiposEquipo" :key="t.id" class="flex-column start-center">
      <h4 class="align-start">Equipo {{ t.nombre }}</h4>
      <div class="grids-wrapper">
      <div class="grid header">
        <b></b>
        <b class="title">Funcionales</b>
        <b class="title">Descompuestos</b>
        <b class="total">Total</b>
        <b>Funcionales con Internet
          <b-icon v-btooltip="helpInternet" icon="question-circle-fill"></b-icon>
        </b>
      </div>
      <div v-for="e of equiposOfType(t.id)" :key="e.id" class="grid">
        <b>{{ nombreEquipo(e.equipo) }}</b>

        <ValidationProvider rules="required" v-slot="v">
          <b-input v-model="e.funcionales" :state="$state(v)" size="sm"
            type=number min=0 @change="change(e)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="v">
          <b-input v-model="e.descompuesto" :state="$state(v)" size="sm"
            type=number min=0 @change="change(e)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </ValidationProvider>

        <b class="total">{{ sum(e) }}</b>

        <ValidationProvider rules="required" v-slot="v">
          <b-input v-model="e.coninternet" :state="$state(v)" size="sm"
            type=number min=0 :max="e.funcionales" @change="change(e)"></b-input>
          <b-error :state="$state(v)">{{ v.errors[0] }}</b-error>
        </ValidationProvider>
      </div>
      </div>
    </div>

    <div class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared :disabled="invalid"
        @click.prevent="$emit('next')">{{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </form>
  </validationobserver>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
  props: {
    value: Array,
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
  },
  created() {
    const promises = [this.getEquipos(), this.getTiposEquipo()]
    Promise.all(promises).then( responses => {
      const equipos  = responses[0]
      const equiposE = this.equipamiento
      this.$emit('input', equipos.map( e => {
        const eE = equiposE.find( i => i.equipo === e.id) || {}
        return {
          equipo: e.id,
          funcionales: eE.funcionales || 0,
          descompuesto: eE.descompuesto || 0,
          coninternet: eE.coninternet || 0,
          tipo: e.tipo
        }
      }))
    })
  },
  computed: {
    ...mapState('escuela', ['equipamiento']),
    ...mapState('encuesta', ['tiposEquipo']),
    ...mapGetters('encuesta', ['nombreEquipo']),
    equiposOfType() {
      return type => this.value.filter( e => e.tipo === type )
    },
    helpInternet() {
      return 'De los equipos funcionales, ¿Cuántos tienen internet?'
    },
  },
  methods: {
    ...mapActions('encuesta', ['getEquipos', 'getTiposEquipo']),
    sum(e) {
      const sum = Number(e.funcionales) +
                  Number(e.descompuesto)
      return sum && sum > 0 ? sum : '---'
    },
    change(e) {
      const conInternet  = Number(e.conInternet)
      const funcionales    = Number(e.funcionales)
      const descompuesto = Number(e.descompuesto)
      if (funcionales < 0) {
        e.funcionales = 0

      } else if (descompuesto < 0) {
        e.descompuesto = 0

      } else if (conInternet < 0) {
        e.conInternet = 0

      }
      if (conInternet > funcionales) {
        e.conInternet = e.funcionales
      }

    }
  },
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 100px 100px 125px 40px 110px;
  gap: 20px;
  margin: 0 auto 10px auto;
  max-width: 100%;
}
.grids-wrapper { overflow-y: auto; max-width: 100%; }
.grid { font-size: 14px; }
.total, .title { margin: auto 0; }
h4 { margin-top: 30px; }
@media screen and (min-width: 725px) {
  .grid { grid-template-columns: 140px 130px 155px 45px 140px; }
  .grid { font-size: inherit; }
}
</style>
