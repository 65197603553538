<template>
  <div class="flex-column">
    <h2>Encuesta de contenidos digitales {{ append }}</h2>
    <hr class="gob"/>

    Docentes, conocemos de su esfuerzo por solventar la falta de recepción de la
    Red Edusat. Por eso queremos conocer cuales son los contenidos educativos
    audiovisuales o sitios web que les sirven de apoyo para consultar y/o
    descargar recursos educativos digitales.

    <ValidationProvider class="field flex-row wrap" rules="required" v-slot="v">
      <b-form-group label="Liga del contenido audiovisual o sitio web" class="grow">
        <b-input v-model="link" :pattern="regex"
          @keyup.enter="$refs.agregar.click()"></b-input>
        <b-error>{{ v.errors[0] }}</b-error>
      </b-form-group>
      <b-button variant="primary" :disabled="v.invalid"
        ref="agregar" @click="add">Agregar</b-button>
    </ValidationProvider>

    <div class="text-center lista">
      <div><b>Lista de contenidos</b></div>
      <template v-if="value && value.length > 0">
        <div v-for="(liga, index) of value" :key="liga" class="link text-left">
          <b>{{ index + 1 }}. </b>
          <b>{{ domain(liga) }}</b>
          <b-icon icon="x" variant="danger" class="x" @click="remove(index)"></b-icon>
          <a :href="liga" target="_blank">{{ liga }}</a>
        </div>
      </template>
      <small v-else class="no-data"> No has registrado ninguna liga aún </small>
    </div>

    <div class="flex-row-reverse space-between-start full-width">
      <b-button variant="gob" squared @click.prevent="$emit('next')">
        {{ nextLegend }}</b-button>
      <div></div>
      <b-button v-if="backLegend" variant="gob" squared
        @click.prevent="$emit('back')">{{ backLegend }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    value: Array, 
    append: { default: '' },
    backLegend: { default: 'Anterior' },
    nextLegend: { default: 'Siguiente' },
  },
  data: () => ({
    link: '',
    // RFC 1034: Domain Names - Concepts and Facilities
    // https://www.ietf.org/rfc/rfc1034.txt (Page 10)
    regex: /^(http|https|ftp|sftp):\/\/[:.0-9A-Za-z-]+(\/[/%#?&=._0-9A-Za-z-]*)?$/,
    regexDomain: /(?<=\/\/)[:.0-9A-Za-z-]+(?=\/|$)/,
  }),
  computed: {
    ...mapState('escuela', ['escuela']),
  },
  created() {
    if (!this.escuela) return
    this.fetchLastEncuesta().then( encuesta =>
      this.$emit('input', [...encuesta.json.contenidos || []])
    )
  },
  methods: {
    ...mapActions('escuela', ['fetchLastEncuesta']),
    domain(link) {
      const matches = link.match(this.regexDomain);
      return matches ? matches[0] : '-- error --';
    },
    add() {
      this.value.push(this.link)
      this.link = ''
    },
    remove(index) {
      this.value.splice(index, 1);
    },
  },
}
</script>

<style scoped>
.x { font-size: 30px; margin-bottom: -4px; cursor: pointer; }

.lista { width: auto; margin: auto; margin-bottom: 30px; max-width: 100%; }
.no-data  { font-style: italic; }
.link { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.icon { margin: 0 3px 0 5px; visibility: visible; color: var(--error); }

.field.flex-row { display: flex; margin: 20px 0; height: auto; }
.field > label {  width:  100%; margin: 0; }
.field > .grow {    width:  100%; margin: 1px 0 10px 0; }
.field > .btn { margin: auto; height: 40px; }

@media screen and (min-width: 760px) {
  .field > .grow { width: auto; flex-grow: 1; margin-right: 10px; }
  .field > .btn { margin: 20px 0; }
}
</style>
